<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Change Password</h6>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="form mx-4">
              <div class="form-row">
                <div class="form-group">
                  <label for="" class="form-label">Old Password <span class="text-danger">*</span></label>
                  <div class="form-data">
                    <input class="form-control" type="text" placeholder="Old Password" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="form-label">New Password <span class="text-danger">*</span></label>
                  <div class="form-data">
                    <input class="form-control" type="text" placeholder="New Password" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="form-label">Confirm Password <span class="text-danger">*</span></label>
                  <div class="form-data">
                    <input class="form-control" type="text" placeholder="Confirm Password" />
                  </div>
                </div>
                <button class="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>